import './FooterComponent.scss';

import React from 'react';

import { getCopyrightLabel } from '../../helpers/getCurrentYear';
import ContactMethodComponent from '../ContactMethodComponent/ContactMethodComponent';
import Icon from '../ui/Icon/Icon';
import Link from '../ui/Link/Link';

interface IFooter {
  columnsArea?: any;
  linkArea?: any;
}
const FooterComponent = ({ columnsArea, linkArea }: IFooter) => {
  return (
    <section className="FooterComponent">
      <div className="FooterComponent__top">
        <div>
          <div>
            <ContactMethodComponent method="phone" />
            <ContactMethodComponent method="mail" />
          </div>
          <div>{columnsArea}</div>
        </div>
      </div>
      <div className="FooterComponent__bottom">
        <div>
          <Icon type="vhv-logo-inverted" />
          <div className="FooterComponent__bottomText">
            <div className="FooterComponent__linkArea">{linkArea}</div>
            <div className="FooterComponent__labelArea">{getCopyrightLabel()}</div>
          </div>
          <div className="FooterComponent__socialArea">
            <Link linkType="external" linkTo={'https://www.instagram.com/vhv_versicherungen/'}>
              <Icon size="24" type="instagram" />
            </Link>
            <Link linkType="external" linkTo={'https://www.facebook.com/vhv.versicherungen/'}>
              <Icon size="24" type="facebook" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterComponent;
