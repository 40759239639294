import alert from './icons/alert.svg';
import all_products from './icons/all-products.svg';
import altersvorsorge from './icons/altersvorsorge.svg';
import architekten from './icons/architekten.svg';
import arrow_down from './icons/arrow-down.svg';
import arrow_left from './icons/arrow-left.svg';
import arrow_line_left from './icons/arrow-line-left.svg';
import arrow_line_right from './icons/arrow-line-right.svg';
import arrow_right from './icons/arrow-right.svg';
import arrow_up from './icons/arrow-up.svg';
import auslandsschutz from './icons/auslandsschutz.svg';
import auto from './icons/auto.svg';
import autoschluessel from './icons/autoschluessel.svg';
import avatar from './icons/avatar.svg';
import basisrente from './icons/basisrente.svg';
import basisrente_sofort from './icons/basisrente-sofort.svg';
import bauen_in_frankreich from './icons/bauen-in-frankreich.svg';
import bauen_technik from './icons/bauen-technik.svg';
import baugewaehrleistung from './icons/baugewaehrleistung.svg';
import bauhandwerk from './icons/bauhandwerk.svg';
import bauhelfer from './icons/bauhelfer.svg';
import bauherren from './icons/bauherren.svg';
import bauleistung from './icons/bauleistung.svg';
import bauleistung_1 from './icons/bauleistung-1.svg';
import baumaschienen_geraete from './icons/baumaschienen-geraete.svg';
import baustoff from './icons/baustoff.svg';
import beitragsdynamik from './icons/beitragsdynamik.svg';
import berufshaftpflicht_architekten_ingenieure from './icons/berufshaftpflicht-architekten-ingenieure.svg';
import berufsunfaehigkeit from './icons/berufsunfaehigkeit.svg';
import bestandskunde from './icons/bestandskunde.svg';
import betreiberhaftpflicht from './icons/betreiberhaftpflicht.svg';
import betriebliche_altersvorsorge from './icons/betriebliche-altersvorsorge.svg';
import betriebshaftpflicht from './icons/betriebshaftpflicht.svg';
import betriebsunterbrechung from './icons/betriebsunterbrechung.svg';
import binoculars from './icons/binoculars.svg';
import biogas from './icons/biogas.svg';
import bookmark from './icons/bookmark.svg';
import briefcase from './icons/briefcase.svg';
import browser from './icons/browser.svg';
import calculator from './icons/calculator.svg';
import calendar from './icons/calendar.svg';
import camera from './icons/camera.svg';
import career from './icons/career.svg';
import cash from './icons/cash.svg';
import check from './icons/check.svg';
import check_double from './icons/check-double.svg';
import check_green from './icons/check-green.svg';
import check_dotted from './icons/check-outline.svg';
import check_solid from './icons/check-solid.svg';
import close from './icons/close.svg';
import cloud_lock from './icons/cloud-lock.svg';
import contract_signing from './icons/contract-signing.svg';
import crosshair from './icons/crosshair.svg';
import cyber from './icons/cyber.svg';
import dachlawine from './icons/dachlawine.svg';
import diebstahl from './icons/diebstahl.svg';
import direktversicherung from './icons/direktversicherung.svg';
import download from './icons/download.svg';
import download_arrow from './icons/download-arrow.svg';
import einbruch_diebstahl from './icons/einbruch-diebstahl.svg';
import elektronik_software from './icons/elektronik-software.svg';
import erneuerbare_energien from './icons/erneuerbare-energien.svg';
import euro from './icons/euro.svg';
import euros from './icons/euros.svg';
import external_link from './icons/external-link.svg';
import facebook from './icons/facebook.svg';
import fahrer from './icons/fahrer.svg';
import fahrerschutz from './icons/fahrerschutz.svg';
import fahrrad_schaden from './icons/fahrrad-schaden.svg';
import fahrzeuge from './icons/fahrzeuge.svg';
import feuer from './icons/feuer.svg';
import feuerrohrrbau from './icons/feuerrohrrbau.svg';
import filter from './icons/filter.svg';
import firmen from './icons/firmen.svg';
import flag from './icons/flag.svg';
import flash from './icons/flash.svg';
import flotte_garant1 from './icons/flotte-garant1.svg';
import flotte_garant5 from './icons/flotte-garant5.svg';
import flotte_garant35 from './icons/flotte-garant35.svg';
import forderungsausfall from './icons/forderungsausfall.svg';
import forensic from './icons/forensic.svg';
import freelancer from './icons/freelancer.svg';
import fullscreen from './icons/fullscreen.svg';
import gas from './icons/gas.svg';
import gebaeude from './icons/gebaeude.svg';
import gebaeude_1 from './icons/gebaeude-1.svg';
import gebaeude_inhalt from './icons/gebaeude-inhalt.svg';
import glas from './icons/glas.svg';
import glasbruch from './icons/glasbruch.svg';
import grid from './icons/grid.svg';
import haftpflicht from './icons/haftpflicht.svg';
import haftpflicht_allgemein from './icons/haftpflicht-allgemein.svg';
import hausrat from './icons/hausrat.svg';
import hausrat_elementar from './icons/hausrat-elementar.svg';
import hausrat_feuer from './icons/hausrat-feuer.svg';
import hausundgrund from './icons/hausundgrund.svg';
import heart from './icons/heart.svg';
import help from './icons/help.svg';
import home from './icons/home.svg';
import hotline from './icons/hotline.svg';
import hotline_thin from './icons/hotline-thin.svg';
import info from './icons/info.svg';
import info_circle from './icons/info-circle.svg';
import inhalt from './icons/inhalt.svg';
import instagram from './icons/instagram.svg';
import jagd from './icons/jagd.svg';
import kaution_buergschaft from './icons/kaution-buergschaft.svg';
import kfz_allgemein from './icons/kfz-allgemein.svg';
import kleinwagen from './icons/kleinwagen.svg';
import kompaktklasse from './icons/kompaktklasse.svg';
import landesverbaende from './icons/landesverbaende.svg';
import lehrer from './icons/lehrer.svg';
import leistungsdynamik from './icons/leistungsdynamik.svg';
import link_extern from './icons/link-extern.svg';
import list from './icons/list.svg';
import location from './icons/location.svg';
import lock from './icons/lock.svg';
import mail from './icons/mail.svg';
import mail_outlined from './icons/mail-outlined.svg';
import marder from './icons/marder.svg';
import mehrfach_kaution from './icons/mehrfach-kaution.svg';
import menu from './icons/menu.svg';
import menu_1 from './icons/menu-1.svg';
import mittelklasse from './icons/mittelklasse.svg';
import montage from './icons/montage.svg';
import moped from './icons/moped.svg';
import motorrad from './icons/motorrad.svg';
import navi from './icons/navi.svg';
import neukunde from './icons/neukunde.svg';
import no_result from './icons/no-result.svg';
import notification from './icons/notification.svg';
import noun_login from './icons/noun-login.svg';
import noun_login_thin from './icons/noun-login-thin.svg';
import oberemittelklasse from './icons/oberemittelklasse.svg';
import oberklasse from './icons/oberklasse.svg';
import pen from './icons/pen.svg';
import pensionszusage from './icons/pensionszusage.svg';
import phone from './icons/phone.svg';
import phone_outlined from './icons/phone-outlined.svg';
import photovoltaik from './icons/photovoltaik.svg';
import photovoltaik_1 from './icons/photovoltaik-1.svg';
import play from './icons/play.svg';
import plus from './icons/plus.svg';
import plus_circled from './icons/plus-circled.svg';
import plus_circled_green from './icons/plus-circled-green.svg';
import press from './icons/press.svg';
import profil from './icons/profil.svg';
import quad from './icons/quad.svg';
import rabattschutz from './icons/rabattschutz.svg';
import rechtsschutz from './icons/rechtsschutz.svg';
import restore_folder from './icons/restore-folder.svg';
import route from './icons/route.svg';
import search from './icons/search.svg';
import secure_euro from './icons/secure-euro.svg';
import share from './icons/share.svg';
import shield_double from './icons/shield-double.svg';
import shield_single from './icons/shield-single.svg';
import shield_triple from './icons/shield-triple.svg';
import smartphone_price from './icons/smartphone-price.svg';
import smiley_happy from './icons/smiley-happy.svg';
import smiley_sad from './icons/smiley-sad.svg';
import speedo from './icons/speedo.svg';
import spinner from './icons/spinner.svg';
import star from './icons/star.svg';
import stop from './icons/stop.svg';
import stopwatch from './icons/stopwatch.svg';
import street from './icons/street.svg';
import suv from './icons/suv.svg';
import tag from './icons/tag.svg';
import teilkasko from './icons/teilkasko.svg';
import tier from './icons/tier.svg';
import tierhalter from './icons/tierhalter.svg';
import time from './icons/time.svg';
import trike from './icons/trike.svg';
import unfall from './icons/unfall.svg';
import unfallschaden from './icons/unfallschaden.svg';
import unfallschaden_fremd from './icons/unfallschaden-fremd.svg';
import unterstuetzungskasse from './icons/unterstuetzungskasse.svg';
import unwetter from './icons/unwetter.svg';
import vandalismus from './icons/vandalismus.svg';
import variorente_klassik from './icons/variorente-klassik.svg';
import verkaufsforderung from './icons/verkaufsforderung.svg';
import vermoegenschaden_haftpflicht from './icons/vermoegenschaden-haftpflicht.svg';
import vertriebstechnik from './icons/vertriebstechnik.svg';
import video from './icons/video.svg';
import video_play from './icons/video-play.svg';
import virtual_event from './icons/virtual-event.svg';
import vollkasko from './icons/vollkasko.svg';
import vorsorge from './icons/vorsorge.svg';
import vorsorge_1 from './icons/vorsorge-1.svg';
import waermepumpe from './icons/waermepumpe.svg';
import warning from './icons/warning.svg';
import warning_thin from './icons/warning-thin.svg';
import wetterrisiko from './icons/wetterrisiko.svg';
import wind from './icons/wind.svg';
import wohn_elementar from './icons/wohn-elementar.svg';
import wohngebaeude_feuer from './icons/wohngebaeude-feuer.svg';
import wohngebaeude_leitungswasser from './icons/wohngebaeude-leitungswasser.svg';
import wohngebaude from './icons/wohngebaude.svg';
import wohnmobil from './icons/wohnmobil.svg';
import wrong_answer from './icons/wrong-answer.svg';
import x from './icons/x.svg';
import bauhelfer_2024 from './icons2024/2024-bauhelfer.svg';
import bauherren_2024 from './icons2024/2024-bauherren.svg';
import bauleistung_2024 from './icons2024/2024-bauleistung.svg';
import berufsunfaehigkeit_2024 from './icons2024/2024-berufsunfaehigkeit.svg';
import camper_2024 from './icons2024/2024-camper.svg';
import car_2024 from './icons2024/2024-car.svg';
import feuerrohbau_2024 from './icons2024/2024-feuerrohbau.svg';
import haftpflicht_2024 from './icons2024/2024-haftpflicht.svg';
import hausrat_2024 from './icons2024/2024-hausrat.svg';
import hausundgrund_2024 from './icons2024/2024-hausundgrund.svg';
import lehrer_2024 from './icons2024/2024-lehrer.svg';
import moped_2024 from './icons2024/2024-moped.svg';
import motorbike_2024 from './icons2024/2024-motorbike.svg';
import photovoltaik_2024 from './icons2024/2024-photovoltaik.svg';
import quad_2024 from './icons2024/2024-quad.svg';
import rechtschutz_2024 from './icons2024/2024-rechtschutz.svg';
import risikoleben_2024 from './icons2024/2024-risikoleben.svg';
import sterbegeld_2024 from './icons2024/2024-sterbegeld.svg';
import tierhalter_2024 from './icons2024/2024-tierhalter.svg';
import trike_2024 from './icons2024/2024-trike.svg';
import unfall_2024 from './icons2024/2024-unfall.svg';
import waermepumpe_2024 from './icons2024/2024-waermepumpe.svg';
import wohngebaeude_2024 from './icons2024/2024-wohngebaeude.svg';
import bomb from './images/bomb.svg';
import skull from './images/skull.svg';
import Union from './images/Union.svg';
import vhv_logo from './images/vhv-logo.svg';
import vhv_logo_4c_1m from './images/vhv-logo-4c-1m.svg';
import vhv_logo_full_alt from './images/vhv-logo-full-alt.svg';
import vhv_logo_inverted from './images/vhv-logo-inverted.svg';
import vhv_logo_subhead from './images/vhv-logo-subhead.svg';
import video_chat from './images/video-chat.svg';

export default {
  'all-products': all_products,
  'arrow-down': arrow_down,
  'arrow-left': arrow_left,
  'arrow-right': arrow_right,
  'arrow-up': arrow_up,
  'arrow-line-left': arrow_line_left,
  'arrow-line-right': arrow_line_right,
  avatar,
  'basisrente-sofort': basisrente_sofort,
  'bauen-in-frankreich': bauen_in_frankreich,
  'bauen-technik': bauen_technik,
  'bauleistung-1': bauleistung_1,
  'baumaschienen-geraete': baumaschienen_geraete,
  'berufshaftpflicht-architekten-ingenieure': berufshaftpflicht_architekten_ingenieure,
  'betriebliche-altersvorsorge': betriebliche_altersvorsorge,
  'check-outline': check_dotted,
  'check-double': check_double,
  'check-green': check_green,
  'check-solid': check_solid,
  'cloud-lock': cloud_lock,
  'download-arrow': download_arrow,
  'contract-signing': contract_signing,
  'einbruch-diebstahl': einbruch_diebstahl,
  'elektronik-software': elektronik_software,
  'erneuerbare-energien': erneuerbare_energien,
  'external-link': external_link,
  'fahrrad-schaden': fahrrad_schaden,
  'flotte-garant1': flotte_garant1,
  'flotte-garant35': flotte_garant35,
  'flotte-garant5': flotte_garant5,
  'gebaeude-1': gebaeude_1,
  'gebaeude-inhalt': gebaeude_inhalt,
  'haftpflicht-allgemein': haftpflicht_allgemein,
  'hausrat-elementar': hausrat_elementar,
  'hausrat-feuer': hausrat_feuer,
  'info-circle': info_circle,
  'kaution-buergschaft': kaution_buergschaft,
  'kfz-allgemein': kfz_allgemein,
  'link-extern': link_extern,
  'mehrfach-kaution': mehrfach_kaution,
  'menu-1': menu_1,
  'mail-outlined': mail_outlined,
  'no-result': no_result,
  'noun-login': noun_login,
  'phone-outlined': phone_outlined,
  'photovoltaik-1': photovoltaik_1,
  'plus-circled': plus_circled,
  'plus-circled-green': plus_circled_green,
  'restore-folder': restore_folder,
  'secure-euro': secure_euro,
  'shield-single': shield_single,
  'shield-double': shield_double,
  'shield-triple': shield_triple,
  'smartphone-price': smartphone_price,
  'smiley-happy': smiley_happy,
  'smiley-sad': smiley_sad,
  'unfallschaden-fremd': unfallschaden_fremd,
  'variorente-klassik': variorente_klassik,
  'vermoegenschaden-haftpflicht': vermoegenschaden_haftpflicht,
  'vhv-logo': vhv_logo,
  'vhv-logo-4c-1m': vhv_logo_4c_1m,
  'vhv-logo-full-alt': vhv_logo_full_alt,
  'vhv-logo-subhead': vhv_logo_subhead,
  'vhv-logo-inverted': vhv_logo_inverted,
  'video-chat': video_chat,
  'video-play': video_play,
  'virtual-event': virtual_event,
  'vorsorge-1': vorsorge_1,
  'wohn-elementar': wohn_elementar,
  'wohngebaeude-feuer': wohngebaeude_feuer,
  'wohngebaeude-leitungswasser': wohngebaeude_leitungswasser,
  'wrong-answer': wrong_answer,
  'warning-thin': warning_thin,
  'noun-login-thin': noun_login_thin,
  'hotline-thin': hotline_thin,
  Union,
  alert,
  altersvorsorge,
  architekten,
  auslandsschutz,
  auto,
  autoschluessel,
  basisrente,
  baugewaehrleistung,
  bauhandwerk,
  bauhelfer,
  bauherren,
  bauleistung,
  baustoff,
  beitragsdynamik,
  berufsunfaehigkeit,
  bestandskunde,
  betreiberhaftpflicht,
  betriebshaftpflicht,
  betriebsunterbrechung,
  binoculars,
  biogas,
  bomb,
  bookmark,
  briefcase,
  browser,
  calculator,
  calendar,
  camera,
  career,
  cash,
  check,
  close,
  crosshair,
  cyber,
  dachlawine,
  diebstahl,
  direktversicherung,
  download,
  euro,
  euros,
  facebook,
  fahrer,
  fahrerschutz,
  fahrzeuge,
  feuer,
  feuerrohrrbau,
  filter,
  firmen,
  flag,
  flash,
  forderungsausfall,
  forensic,
  freelancer,
  fullscreen,
  gas,
  gebaeude,
  glas,
  glasbruch,
  grid,
  haftpflicht,
  hausrat,
  hausundgrund,
  heart,
  help,
  home,
  hotline,
  info,
  inhalt,
  instagram,
  jagd,
  kleinwagen,
  kompaktklasse,
  landesverbaende,
  lehrer,
  leistungsdynamik,
  list,
  location,
  lock,
  mail,
  marder,
  menu,
  mittelklasse,
  montage,
  moped,
  motorrad,
  navi,
  neukunde,
  notification,
  oberemittelklasse,
  oberklasse,
  pen,
  pensionszusage,
  phone,
  photovoltaik,
  play,
  plus,
  press,
  profil,
  quad,
  rabattschutz,
  rechtsschutz,
  route,
  search,
  share,
  skull,
  speedo,
  spinner,
  star,
  stop,
  stopwatch,
  street,
  suv,
  tag,
  teilkasko,
  tier,
  tierhalter,
  time,
  trike,
  unfall,
  unfallschaden,
  unterstuetzungskasse,
  unwetter,
  vandalismus,
  verkaufsforderung,
  vertriebstechnik,
  video,
  vollkasko,
  vorsorge,
  waermepumpe,
  warning,
  wetterrisiko,
  wind,
  wohngebaude,
  wohnmobil,
  x,
  bauhelfer_2024,
  bauherren_2024,
  bauleistung_2024,
  berufsunfaehigkeit_2024,
  wohnmobil_2024: camper_2024,
  auto_2024: car_2024,
  feuerrohrrbau_2024: feuerrohbau_2024,
  haftpflicht_2024,
  hausrat_2024,
  hausundgrund_2024,
  lehrer_2024,
  moped_2024,
  motorrad_2024: motorbike_2024,
  photovoltaik_2024,
  quad_2024,
  rechtschutz_2024,
  risikoleben_2024,
  sterbegeld_2024,
  tierhalter_2024,
  trike_2024,
  unfall_2024,
  waermepumpe_2024,
  wohngebaude_2024: wohngebaeude_2024
};
