import React, { useContext } from 'react';

import BrokerBcContext from '../../../contexts/BrokerBcContext';
import ExpertSearchContext from '../../../contexts/ExpertSearchContext';
import getFormEngineWebBaseUrl from '../../../helpers/getFormEngineWebBaseUrl';
import useBroker from '../../../hooks/useBroker';
import FullscreenOverlay from '../FullscreenOverlay/FullscreenOverlay';
import FullscreenOverlaySlider from '../FullscreenOverlaySlider/FullscreenOverlaySlider';

const BrokerBc = () => {
  const urlParamBroker = useBroker();

  const { expert: expertSearchBroker } = useContext(ExpertSearchContext) || {};

  const { brokerFromProps, visibleBrokerBc, hideBrokerBc } = useContext(BrokerBcContext) || {};

  const broker = brokerFromProps || urlParamBroker || expertSearchBroker;

  const { email: encryptedEmail } = broker || {};

  const param = encryptedEmail ? `?datato=${encryptedEmail}` : '';
  const frameSrc = `${getFormEngineWebBaseUrl()}/formEngineWeb/vhv/formular-dba-${visibleBrokerBc}.htm${param}`;

  return (
    <FullscreenOverlay isVisible={visibleBrokerBc !== ''} hideOverlay={() => hideBrokerBc()}>
      <FullscreenOverlaySlider>
        <iframe className="itform" src={frameSrc}></iframe>
      </FullscreenOverlaySlider>
    </FullscreenOverlay>
  );
};

export default BrokerBc;
