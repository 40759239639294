// DownloadsComponent.tsx

import './DownloadsComponent.scss';

import React from 'react';

import Container from '../ui/Container/Container';
import Icon from '../ui/Icon/Icon';
import Link from '../ui/Link/Link';

interface Download {
  title: string;
  description: string;
  linkType: string;
  linkTo: string;
}

interface DownloadsComponentProps {
  downloads: { [key: string]: any };
}

const mapDownloads = ({ downloads }: DownloadsComponentProps): Download[] => {
  const result: Download[] = [];
  for (const key in downloads) {
    const { title, description, linkType = '', linkTo } = downloads[key];
    if (title && description) {
      let resolvedLinkTo = '';

      if (linkType === 'asset' && linkTo) {
        resolvedLinkTo = linkTo['@path'] || linkTo;
      } else if (linkType === 'printed_media' && linkTo) {
        resolvedLinkTo = linkTo;
      } else if (linkType === 'page' && linkTo?.path) {
        resolvedLinkTo = linkTo.path;
      } else if (linkType === 'external') {
        resolvedLinkTo = linkTo;
      }

      result.push({
        title: title,
        description: description,
        linkType: linkType,
        linkTo: resolvedLinkTo
      });
    }
  }
  return result;
};

const DownloadsComponent: React.FC<DownloadsComponentProps> = ({ downloads }) => {
  const mappedDownloads = mapDownloads({ downloads });

  return (
    <Container>
      <div id="downloads" className="DownloadsComponent">
        <h2>
          Weitere <strong>Informationen</strong> zum <strong>Download</strong>
        </h2>
        <div>
          {mappedDownloads.map((download, i) => (
            <Link
              extraClass="DownloadsComponent__DownloadItem"
              key={i}
              showIcon
              linkType={download.linkType}
              linkTo={download.linkTo}
            >
              <div className="DownloadsComponent__TextSection">
                <div className="DownloadsComponent__ItemHeadline">{download.title}</div>
                <div className="DownloadsComponent__ItemText">{download.description}</div>
              </div>
              <div>
                <Icon size="3.2rem" type="download-arrow" />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default DownloadsComponent;
