import './Collapsible.scss';

import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { CSSTransition } from 'react-transition-group';

import extractLinkPath from '../../../helpers/extractLinkPath';
import getVariantClasses from '../../../helpers/getVariantClasses';
import useBreakpoint from '../../../hooks/useBreakpoint';
import Icon from '../Icon/Icon';

export interface IContainerProps {
  variants?: TCollapsibleVariants[];
  header?: any;
  content?: any;
  children?: any;
}

export type TCollapsibleVariants =
  | ''
  | 'reverse'
  | 'styled-html'
  | 'no-border-bottom'
  | 'header-yellow';

const Collapsible: React.FC<IContainerProps> = ({ header, content, variants, children }) => {
  const innerHtml = content && { __html: extractLinkPath(content) };
  const [isRendered, setIsRendered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const isMaxVpS = useBreakpoint('s');

  const showContent = () => {
    setIsRendered(true);
    setTimeout(() => {
      setIsVisible(true);
    });
  };

  const hideContent = () => {
    const delay = isMaxVpS ? 100 : 250;

    setIsVisible(false);
    setTimeout(() => {
      setIsRendered(false);
    }, delay);
  };

  const toggleContentVisibility = () => {
    isRendered ? hideContent() : showContent();
  };

  return (
    <div className={getVariantClasses('Collapsible', variants)} onClick={toggleContentVisibility}>
      <div className={`Collapsible__headerWrapper${isVisible ? ' open' : ''}`}>
        <h3 className="Collapsible__header">{header}</h3>
        <Icon type="plus" />
      </div>
      <CSSTransition in={isRendered} mountOnEnter unmountOnExit timeout={0}>
        <AnimateHeight animateOpacity={true} height={isVisible ? 'auto' : 0} duration={250}>
          <div className="Collapsible__contentArea">
            {children ? (
              <div className="Collapsible__content">{children}</div>
            ) : (
              <div className="Collapsible__content" dangerouslySetInnerHTML={innerHtml} />
            )}
          </div>
        </AnimateHeight>
      </CSSTransition>
    </div>
  );
};

export default Collapsible;
