import './PartnerArea.scss';

import React, { useContext, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import Icon from '../../components/ui/Icon/Icon';
import Image from '../../components/ui/Image/Image';
import Link from '../../components/ui/Link/Link';
import SiteDataContext from '../../contexts/SiteDataContext';
import useOutsideClick from '../../hooks/useOutsideClick';
import partnerData from './partnerData';

const PartnerArea = () => {
  const { organizations } = useContext(SiteDataContext) || {};

  const [selectedState, setSelectedState] = useState('');
  const getTileContent = (name, image) => (
    <>
      <div className="m-orga-tile__front">
        <Image src={image} />
      </div>
      <div className="m-orga-tile__back">{name}</div>
    </>
  );
  const getPartnerTile = (name, image, website) => {
    return (
      <div className="m-orga-tile__item js-dom-filter">
        {website ? (
          <Link linkTo={website} linkType="external">
            <div className="m-orga-tile m-orga-tile--link">{getTileContent(name, image)}</div>
          </Link>
        ) : (
          <div>
            <div className="m-orga-tile__plain">{getTileContent(name, image)}</div>
          </div>
        )}
      </div>
    );
  };

  const getSelector = () => {
    const [isSelectorOpen, setIsSelectorOpen] = useState<boolean>(false);
    const buttonRef = useRef(null);
    const selectorRef = useRef(null);

    useOutsideClick(selectorRef, () => setIsSelectorOpen(false), isSelectorOpen, buttonRef);
    return (
      <div className="PartnerAreaSelector">
        <div
          className={`PartnerAreaSelector__button${
            isSelectorOpen ? ' PartnerAreaSelector__button--active' : ''
          }`}
          ref={buttonRef}
          onClick={() => setIsSelectorOpen(!isSelectorOpen)}
        >
          <span className="PartnerAreaSelector__label">
            {partnerData[selectedState] || 'Alle Bundesland'}
          </span>
          {partnerData && Object.keys(partnerData).length > 0 && (
            <Icon type="arrow-down" className="ico-arrow-down" />
          )}
          <CSSTransition
            in={isSelectorOpen}
            mountOnEnter
            unmountOnExit
            timeout={200}
            classNames={'fade-transform-top-2'}
          >
            <ul className="PartnerAreaSelector__selector" ref={selectorRef}>
              {Object.keys(partnerData).map((state, key) => (
                <li
                  key={key}
                  className="PartnerAreaSelector__option"
                  onClick={() => {
                    setIsSelectorOpen(false);
                    setSelectedState(state);
                  }}
                >
                  {partnerData[state]}
                </li>
              ))}
            </ul>
          </CSSTransition>
        </div>
      </div>
    );
  };
  return (
    <section className="l-content__section">
      <div>
        <div className="m-event-list__filter m-event-list__filter--medium PartnerArea__selector">
          <div>Wähle ein Bundesland</div>
          {getSelector()}
        </div>

        <div className="m-orga-tile__wrapper">
          {partnerData &&
            Object.keys(partnerData).map((state, key) => {
              const filteredOrgas = organizations?.filter(organisation =>
                organisation.states?.includes(state)
              );
              return filteredOrgas?.length > 0 &&
                (selectedState === '' || selectedState === state) ? (
                <>
                  <div key={key} className="m-orga-tile__item js-dom-filter">
                    <div>
                      <div className="m-orga-tile__plain">
                        <div>{partnerData[state]}</div>
                      </div>
                    </div>
                  </div>
                  {filteredOrgas.map(organization => {
                    const { name, image, website } = organization;
                    return getPartnerTile(name, image, website);
                  })}
                </>
              ) : null;
            })}
        </div>
      </div>
    </section>
  );
};

export default PartnerArea;
