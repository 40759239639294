import './CorporateContact.scss';

import React, { useContext } from 'react';

import ProductGroupsContext from '../../../contexts/ProductGroupsContext';
import Icon from '../Icon/Icon';
import Link from '../Link/Link';
import { ILinkProps } from '../Link/Link';

const headline = 'Das Team vom Vertriebs-Service-Center freut sich auf Ihren Anruf.';
export const corporateContactPhone = '0221/20702-96';
export const corporateContactAvailability = 'Montag - Freitag 8 - 18 Uhr';
const cta = 'Unser Service für Sie';

const links: ILinkProps[] = [
  { label: 'Schaden\u00a0melden', linkTo: '/firmen/kontakt' },
  {
    label: 'Forderungsausfall',
    linkTo: '/firmen/produkte/forderungsausfall'
  },
  {
    label: 'Ihr\u00a0Kreditportal',
    linkTo: '/firmen/produkte/kautionsversicherung/kreditportal'
  },
  { label: 'Allgemeiner\u00a0Kontakt', linkTo: '/firmen/kontakt' }
];

interface ICorporateContactProps {
  variant?: 'modal';
  hideOverlay?: () => void;
}

const CorporateContact: React.FC<ICorporateContactProps> = ({ variant, hideOverlay }) => {
  const isModal = variant === 'modal';

  const { activeProduct } = useContext(ProductGroupsContext) || {};
  const { customContact, contactMethod = 'standard' } = activeProduct || {};

  const contact = {
    custom: customContact,
    standard: {
      heading: headline,
      phoneNumber: corporateContactPhone,
      availability: corporateContactAvailability
    }
  };

  return (
    <div className={`CorporateContact${isModal ? ' CorporateContact--modal' : ''}`}>
      <div className="CorporateContact__col">
        <h2 className="CorporateContact__headline">{contact[contactMethod].heading}</h2>
        <a href={`tel:${contact[contactMethod].phoneNumber}`} className="CorporateContact__phone">
          <Icon type="hotline" className="ico-hotline" />
          <span>{contact[contactMethod].phoneNumber}</span>
        </a>
        <div className="CorporateContact__availability">{contact[contactMethod].availability}</div>
      </div>
      <div className="CorporateContact__col">
        <div className="CorporateContact__cta">{cta}</div>
        <ul className="CorporateContact__links">
          {links.map((link, index) => {
            return (
              <li key={index}>
                <Link
                  showIcon
                  {...link}
                  onClick={() => {
                    if (hideOverlay) {
                      hideOverlay();
                    }
                  }}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default CorporateContact;
