import './ProductFinderSelector.scss';

import React, { useContext, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import ProductFinderContext from '../../../../contexts/ProductFinderContext';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import Icon from '../../Icon/Icon';
import { getLabel } from '../ProductFinderLabelMap';

interface IProductFinderSelectorProps {
  type: 'occupation' | 'branch';
  color?: 'gray';
}

const ProductFinderSelector: React.FC<IProductFinderSelectorProps> = ({ type, color }) => {
  const {
    selectedOccupation,
    setSelectedOccupation,
    selectedBranch,
    setSelectedBranch,
    occupationTags,
    branchTags
  } = useContext(ProductFinderContext) || {};

  const [isSelectorOpen, setIsSelectorOpen] = useState<boolean>(false);

  const isBranchContent = type === 'branch';

  // const header = isBranchContent ? 'Ihre Branche' : 'Ihr Unternehmenstyp';
  const selectedItem = isBranchContent ? selectedBranch : selectedOccupation;

  const setSelectedItem = isBranchContent ? setSelectedBranch : setSelectedOccupation;

  const selectionOptions = [...(isBranchContent ? branchTags : occupationTags)].filter(
    option => option !== selectedItem
  );

  const buttonRef = useRef(null);
  const selectorRef = useRef(null);

  useOutsideClick(selectorRef, () => setIsSelectorOpen(false), isSelectorOpen, buttonRef);

  return branchTags ? (
    <div className="ProductFinderSelector">
      <div
        className={`ProductFinderSelector__button${
          isSelectorOpen ? ' ProductFinderSelector__button--active' : ''
        }${color ? ` ProductFinderSelector__button--${color}` : ''}`}
        ref={buttonRef}
        onClick={() => setIsSelectorOpen(!isSelectorOpen)}
      >
        <span className="ProductFinderSelector__label">{getLabel(selectedItem)}</span>
        {selectionOptions && selectionOptions.length > 0 && (
          <Icon type="arrow-down" className="ico-arrow-down" />
        )}
        <CSSTransition
          in={isSelectorOpen}
          mountOnEnter
          unmountOnExit
          timeout={200}
          classNames={'fade-transform-top-2'}
        >
          <ul className="ProductFinderSelector__selector" ref={selectorRef}>
            {selectionOptions.map((option, key) => (
              <li
                key={key}
                className="ProductFinderSelector__option"
                onClick={() => {
                  setIsSelectorOpen(false);
                  setSelectedItem(option);
                }}
              >
                {getLabel(option)}
              </li>
            ))}
          </ul>
        </CSSTransition>
      </div>
    </div>
  ) : null;
};

export default ProductFinderSelector;
