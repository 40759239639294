import React, { createContext, useContext } from 'react';

import Icon from '../components/ui/Icon/Icon';
import SiteDataContext from '../contexts/SiteDataContext';
import extractChildren from '../helpers/extractChildren';
import { getCopyrightLabel } from '../helpers/getCurrentYear';
import useBroker from '../hooks/useBroker';

export const LandingPageContext = createContext(null);

const LandingPage = ({ children, landingPageStyle, disableP13n, hideContact }) => {
  const {
    headerArea,
    stageArea,
    benefitArea,
    contentArea,
    socialSharingArea,
    relatedContentArea,
    contactArea
  } = extractChildren(children);

  const { telephoneNumber, availability } = contactArea?.props || {};
  const { footer: { homeFooterLinkArea = null } = {} } = useContext(SiteDataContext) || {};

  const lpContact = useBroker();

  let stageAreaComponent = {};

  const relevantStageComponentTemplates = ['PriceCallToActionComponent', 'CallToActionComponent'];

  stageArea?.props?.nodes?.forEach(componentKey => {
    const component = stageArea.props[componentKey];

    if (relevantStageComponentTemplates.includes(component?.template)) {
      stageAreaComponent = component;
    }
  });

  return (
    <>
      <LandingPageContext.Provider
        value={{
          landingPageStyle,
          telephoneNumber,
          availability,
          stageAreaComponent,
          lpContact,
          disableP13n,
          hideContact
        }}
      >
        {headerArea}
        <main className="l-content l-content--landing">
          {stageArea}
          {benefitArea}
          {contentArea}
          {socialSharingArea}
          {relatedContentArea}
          {contactArea}
        </main>
        <footer
          className={`l-footer l-footer--landing${
            landingPageStyle ? 'l-footer--landing-' + landingPageStyle : ''
          }`}
        >
          <div>
            <div className="l-footer__windup l-footer__windup--minimal l-footer__windup--landing">
              <div className="float-left">
                <div className="float-left">
                  {homeFooterLinkArea}
                  {getCopyrightLabel()}
                </div>
              </div>
              <div className="float-right">
                <a href="https://www.vhv.de/">
                  <Icon type="link-extern" className="ico-link-extern"></Icon>
                  &nbsp; <strong> Zur Website vhv.de</strong>
                </a>
              </div>
            </div>
          </div>
        </footer>
        {!hideContact && !disableP13n && lpContact && (
          <div className="l-footer__bottom-line">
            <div>
              Ein Produkt der VHV vermittelt durch Vertriebspartner
              {lpContact.contact}, {lpContact.name}
            </div>
          </div>
        )}
      </LandingPageContext.Provider>
    </>
  );
};

export default LandingPage;
