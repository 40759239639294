import './WhyVHVComponent.scss';

import React from 'react';

import useSignetScrollTransitionFallback from '../../hooks/useSignetScrollTransitionFallback';
import Heart from '../../styles/assets/images/3D-heart.webp';
import Shield from '../../styles/assets/images/3D-shield.webp';
import Star from '../../styles/assets/images/3D-star.webp';
import Container from '../ui/Container/Container';

// const style = { '--index': index } as React.CSSProperties;

const Circles = () => {
  return (
    <div className="circles">
      {[1, 2, 3].map((circle, index) => {
        const style = { '--index': index } as React.CSSProperties;
        return (
          <div key={index} className="circle" style={style}>
            <svg index={circle} fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle />
              <circle />
              <circle />
            </svg>
            <span className="number" index={circle}>
              1
            </span>
          </div>
        );
      })}
    </div>
  );
};

const WhyVHVComponent = () => {
  useSignetScrollTransitionFallback('.steps', '.card');

  return (
    <Container>
      <div className="WhyVHVComponent">
        <h2>
          Warum VHV? <strong>Drei gute Gründe.</strong>
        </h2>
        <div className="steps">
          <div className="circles">
            <div className="circle" style={{ '--index': '0' }}>
              <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle />
                <circle />
                <circle />
              </svg>
              <span className="number" index="1">
                1
              </span>
            </div>
            <div className="line"></div>
            <div className="circle" style={{ '--index': '1' }}>
              <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle />
                <circle />
                <circle />
              </svg>
              <span className="number" index="2">
                2
              </span>
            </div>
            <div className="line"></div>
            <div className="circle" style={{ '--index': '2' }}>
              <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle />
                <circle />
                <circle />
              </svg>
              <span className="number" index="3">
                3
              </span>
            </div>
          </div>
          <div className="cards">
            <div className="card" style={{ '--index': '0' }}>
              <div className="inner">
                <div className="text">
                  <div className="title">
                    Miteinander wachsen: Versicherungsschutz, der Sie auch
                    <b> in Zukunft</b> optimal schützt
                  </div>
                  <p className="paragraph">
                    Wir entwickeln unsere Produkte laufend weiter und garantieren so zukunftssichere
                    Leistungen, von denen auch Bestandskunden automatisch profitieren.
                  </p>
                </div>
                <div className="image">
                  <img src={Shield} alt="shield" />
                </div>
              </div>
            </div>
            <div className="card" style={{ '--index': '1' }}>
              <div className="inner">
                <div className="text">
                  <div className="title">
                    Füreinander da sein: <b>Kundeninteressen</b> stehen für uns an erster Stelle
                  </div>
                  <p className="paragraph">
                    Wir sind von Haus aus ein Verein auf Gegenseitigkeit. Heißt: Wir kalkulieren so,
                    dass die, die Unterstützung brauchen, auch bekommen.
                  </p>
                </div>
                <div className="image">
                  <img src={Heart} alt="heart" />
                </div>
              </div>
            </div>
            <div className="card" style={{ '--index': '2' }}>
              <div className="inner">
                <div className="text">
                  <div className="title">
                    Von Erfahrung profitieren: Wir sind einer der
                    <b> führenden Kfz-Versicherer</b>
                  </div>
                  <p className="paragraph">
                    2,5 Mio. Kfz-Privatkunden vertrauen der VHV. Als Versicherer mit Tradition und
                    Erfahrung garantieren wir Ihnen Top-Leistungen zu einem günstigen Preis.
                  </p>
                </div>
                <div className="image">
                  <img src={Star} alt="star" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default WhyVHVComponent;
