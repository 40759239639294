import React, { useContext } from 'react';

import Icon from '../components/ui/Icon/Icon';
import Image from '../components/ui/Image/Image';
import Link from '../components/ui/Link/Link';
import ContactInformationContext from '../contexts/ContactInformationContext';
import CorporateContactContext from '../contexts/CorporateContactContext';
import ProductGroupsContext from '../contexts/ProductGroupsContext';
import SiteDataContext from '../contexts/SiteDataContext';
import getCalculatorPath from '../helpers/getCalculatorPath';

const ContentHeaderArea = ({ headline, subHeadline, teaserText, backgroundImage }) => {
  const { pageScope } = useContext(SiteDataContext) || {};

  const { activeProduct: product } = useContext(ProductGroupsContext) || {};

  const isInPrivateRealm = pageScope === 'private';
  const { setIsContactInformationModalVisible } = useContext(ContactInformationContext) || {};

  const {
    linkTo: calculatorPage,
    hideCta,
    calcIcon,
    calcLabel,
    contact,
    showExpert,
    contactMethod
  } = product || {};

  const showExpertSearch = contactMethod == 'expert' || (!contactMethod && showExpert);

  const { getExpertSearchCta } = useContext(CorporateContactContext) || {};

  const { expertCallback, expertLabel, expertIconClass } =
    getExpertSearchCta(showExpertSearch) || {};
  return (
    <div>
      <header>
        <Image
          extraClass="l-content__header"
          src={backgroundImage}
          isBackgroundImage
          sizes={{ w: 1800 }}
        >
          <div>
            {subHeadline && <div>{subHeadline}</div>}
            <h1
              dangerouslySetInnerHTML={{
                __html: headline
              }}
            />
            {teaserText && <p>{teaserText}</p>}
            {product && !hideCta && (calculatorPage || contact) && (
              <div className="l-content__header__button-group">
                {isInPrivateRealm ? (
                  contact && (
                    <button
                      className={`m-button m-button--opaque ${
                        !calculatorPage ? 'm-button--light' : ''
                      } m-button--single-line tag-content-header-contact ga-content-header-contact`}
                      // fullscreen-overlay-app-trigger="contactInformation"
                      // fullscreen-overlay-args="{ selectedProduct: '${contact}' }"
                      onClick={() => {
                        setIsContactInformationModalVisible(true, {
                          isActiveProductVariant: true
                        });
                      }}
                    >
                      <Icon type="hotline" className="ico-hotline ga-content-header-contact" />
                      Beraten lassen
                    </button>
                  )
                ) : (
                  <a
                    className={`m-button m-button--opaque ${
                      calculatorPage ? 'm-button--light' : ''
                    } m-button--single-line tag-content-header-contact ga-content-header-contact`}
                    // fullscreen-overlay-app-trigger="expertSearch"
                    onClick={expertCallback}
                  >
                    <i className={expertIconClass} />
                    {expertLabel}
                  </a>
                )}
                {calculatorPage && (
                  <Link
                    {...getCalculatorPath(calculatorPage)}
                    extraClass="m-button m-button--single-line tag-content-header-calc ga-content-header-calc"
                  >
                    <Icon
                      type={calcIcon}
                      className={`ico-${calcIcon} ga-content-header-calc`}
                    ></Icon>

                    {calcLabel || 'Beitrag berechnen'}
                  </Link>
                )}
              </div>
            )}
          </div>
        </Image>
      </header>
    </div>
  );
};

export default ContentHeaderArea;
