import './ComparisonTableComponent.scss';

import React, { useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import scrollToElementById from '../../helpers/scrollToElementById';
import useIntersect from '../../hooks/useIntersect';
import useVpWidth from '../../hooks/useVpWidth';
import NewButtonComponent from '../NewButtonComponent/NewButtonComponent';
import Container from '../ui/Container/Container';
import Icon from '../ui/Icon/Icon';

const TableFooter = () => {
  return (
    <div className="ComparisonTableComponent__tableFooter">
      <NewButtonComponent
        linkTo=""
        onClick={() => scrollToElementById('downloads')}
        icon="download-arrow"
        label="Alle Informationen zum Download"
        variant="light"
      />
      <div className="ComparisonTableComponent__tableFooterButtonWrapper">
        <NewButtonComponent icon="hotline-thin" label="Beraten lassen" variant="outlined" />
        <NewButtonComponent icon="calculator" label="Jetzt berechnen" />
      </div>
    </div>
  );
};

const iconMapping = {
  check: 'check-solid',
  doubleCheck: 'check-double',
  outlineCheck: 'check-outline',
  cross: 'plus',
  none: ''
};

const Row = ({ row }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="row" data-table-row>
      <div className="row-col">
        <div className="col">
          <div className="inner">
            <div className="detail" data-table-detail onClick={() => setIsOpen(state => !state)}>
              <div className="headline">
                {row.benefit}
                <div>
                  <Icon
                    size="2.4rem"
                    type="info-circle"
                    className={`${!isOpen ? ' is-visible' : ''}`}
                  />
                  <Icon size="2.4rem" type="plus" className={`${isOpen ? ' is-visible' : ''}`} />
                </div>
              </div>
              <AnimateHeight
                height={isOpen ? 'auto' : 0}
                duration={200}
                animateOpacity
                className="description"
              >
                <div className="wrapper">
                  <div className="inner">
                    <p className="text">{row.description}</p>
                  </div>
                </div>
              </AnimateHeight>
            </div>
          </div>
        </div>
      </div>
      <div className="row-col">
        {row.nodes.map((cellKey, index) => {
          const cell = row[cellKey];
          return (
            <div className="col content" key={index}>
              <div className="inner">
                {cell.icon && iconMapping[cell.icon] && <Icon type={iconMapping[cell.icon]} />}
                {cell.description && <p dangerouslySetInnerHTML={{ __html: cell.description }} />}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Rows = ({ rowArea }) => {
  return Object.keys(rowArea)
    .filter(key => {
      const row = rowArea[key];
      return row.nodes && row.nodes.length > 0;
    })
    .map((key, index) => <Row key={index} row={rowArea[key]} />);
};

const TableHeader = ({ tableHeadRef, tableStickyRef, tableStickyIndicatorRef, rates }) => {
  return (
    <>
      <div data-table-sticky-indicator ref={tableStickyIndicatorRef}></div>
      <div className="sticky" data-table-sticky ref={tableStickyRef}>
        <div className="ComparisonTableComponent__tableHead" data-table-head ref={tableHeadRef}>
          <div className="row">
            <div className="row-col">
              <div className="col"></div>
            </div>
            <div className="row-col">
              {rates?.nodes.map((rateKey, index) => (
                <div className="col" key={index}>
                  <span>{rates[rateKey].name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ComparisonTableComponent = ({ title, rates, rowArea }) => {
  const tableStickyIndicatorRef = useRef(null);
  const tableStickyRef = useRef(null);
  const tableHeadRef = useRef(null);
  const tableBodyRef = useRef(null);
  const [tableBodyFadeRight, setTableBodyFadeRight] = useState(true);

  useEffect(() => {
    const tableHead = tableHeadRef.current;
    const tableBody = tableBodyRef.current;

    const handleScroll = event => {
      const scrollPos = event.target.scrollLeft;
      const scrollWidth = tableBodyRef.current.scrollWidth;
      const elementWidth = tableBodyRef.current.clientWidth;
      const needsFadeRight = scrollWidth - elementWidth !== scrollPos;

      needsFadeRight && setTableBodyFadeRight(true);
      !needsFadeRight && setTableBodyFadeRight(false);

      if (event.target === tableBody) {
        tableHead.scrollTo({ left: scrollPos });
      } else {
        tableBody.scrollTo({ left: scrollPos });
      }
    };

    tableBody.addEventListener('scroll', handleScroll);
    tableHead.addEventListener('scroll', handleScroll);

    return () => {
      tableBody.removeEventListener('scroll', handleScroll);
      tableHead.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const observerOptions = {
    root: null,
    rootMargin: '-80px 0px 0px 0px',
    threshold: [0, 1]
  };
  const isIntersected = useIntersect(tableStickyIndicatorRef, false, observerOptions);
  useEffect(() => {
    const tableSticky = tableStickyRef.current;
    !isIntersected ? tableSticky.classList.add('docked') : tableSticky.classList.remove('docked');
  }, [isIntersected]);

  const [tableBodyFullWidth, setTableBodyFullWidth] = useState(false);
  const vpWidth = useVpWidth();
  useEffect(() => {
    const scrollWidth = tableBodyRef.current.scrollWidth;
    const elementWidth = tableBodyRef.current.clientWidth;
    const needsFullWidth = scrollWidth > elementWidth;

    needsFullWidth && !tableBodyFullWidth && setTableBodyFullWidth(true);
    !needsFullWidth && tableBodyFullWidth && setTableBodyFullWidth(false);

    // const columnCount = rowArea[0].nodes.length;
    // columnCount === 3 && ;
  }, [vpWidth]);

  return (
    <Container>
      <div
        className={`ComparisonTableComponent${tableBodyFullWidth ? ' ComparisonTableComponent--table-full-width' : ''}${tableBodyFadeRight ? ' ComparisonTableComponent--fade-right' : ''}`}
      >
        {title && <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>}
        <section>
          <div className="ComparisonTableComponent__table">
            <TableHeader
              tableHeadRef={tableHeadRef}
              tableStickyRef={tableStickyRef}
              tableStickyIndicatorRef={tableStickyIndicatorRef}
              rates={rates}
            />
            <div className="ComparisonTableComponent__tableBody" data-table-body ref={tableBodyRef}>
              {rowArea && <Rows rowArea={rowArea} />}
            </div>
          </div>
          <TableFooter />
        </section>
      </div>
    </Container>
  );
};

export default ComparisonTableComponent;
