import React, { createContext, useState } from 'react';
const BrokerBcContext = createContext(null);

export const BrokerBcContextProvider = ({ children }) => {
  const initialValues = {
    brokerFromProps: null,
    visibleBrokerBc: ''
  };

  const [brokerFromProps, setBrokerFromProps] = useState(initialValues.brokerFromProps);
  const [visibleBrokerBc, dispatchVisibleBrokerBc] = useState<string>(
    initialValues.visibleBrokerBc
  );
  const hideBrokerBc = () => {
    dispatchVisibleBrokerBc(initialValues.visibleBrokerBc);
    setBrokerFromProps(initialValues.brokerFromProps);
  };

  const setVisibleBrokerBc = (visibleBrokerBc: string, broker?: any) => {
    dispatchVisibleBrokerBc(visibleBrokerBc);
    setBrokerFromProps(broker);
  };

  return (
    <BrokerBcContext.Provider
      value={{
        brokerFromProps,
        visibleBrokerBc,
        setVisibleBrokerBc,
        hideBrokerBc
      }}
    >
      {children}
    </BrokerBcContext.Provider>
  );
};
export default BrokerBcContext;
