import './ProductFinder.scss';

import React, { useContext } from 'react';

import Columns from '../Columns/Columns';
import Icon from '../Icon/Icon';
import ProductFinderContext from '../../../contexts/ProductFinderContext';
import ProductFinderSelector from './ProductFinderSelector/ProductFinderSelector';

const ProductFinder = () => {
  const { isProductFinderModalVisible, setIsProductFinderModalVisible, filteredProducts } =
    useContext(ProductFinderContext) || {};

  const amountOfProducts = [...filteredProducts.primary, ...filteredProducts.secondary].length;

  return (
    <div className="ProductFinder">
      <div className="ProductFinder__wrapper">
        <Columns widths={[1, 3]} flexContent="hcentered" gutter="s" breakpoint="xl" wrap={false}>
          <div className="ProductFinder__text">Welche Versicherungen brauche ich?</div>
          <div className="ProductFinder__filter">
            <ProductFinderSelector type="occupation" />
            <ProductFinderSelector type="branch" />
            <div className="ProductFinder__submit">
              <div
                className="ProductFinder__button"
                onClick={() => setIsProductFinderModalVisible(!isProductFinderModalVisible)}
              >
                <Icon
                  type="Union"
                  size={{ width: '2.5rem', height: '2.5rem' }}
                  className="ProductFinder__icon"
                />
                {`Produktfinder starten${
                  amountOfProducts > 0 ? ' (' + amountOfProducts + ')' : ''
                }`}
              </div>
            </div>
          </div>
        </Columns>
      </div>
    </div>
  );
};

export default ProductFinder;
