import './BrokerSearch.scss';

import classNames from 'classnames';
import React, { FormEvent, useEffect, useState } from 'react';

import useBreakpoint from '../../../hooks/useBreakpoint';
import type { Marker } from '../../../hooks/useBrokerSearch';
import useCookieByKey from '../../../hooks/useCookieByKey';
import { pushToDataLayer } from '../../../hooks/useCookiePolicy';
import useUrlParams from '../../../hooks/useUrlParams';
import CookieBox from '../CookieBox/CookieBox';
import Icon from '../Icon/Icon';
import BrokerSearchResults from './BrokerSearchResults';

const getErrorMessage = (errorCode: string) => {
  switch (errorCode) {
    case 'geoApiFail':
      return 'Es gab einen Fehler mit der GeoCode Api.';
    case 'geoFail':
      return 'Ihre Postleitzahl konnte leider nicht automatisch ermittelt werden.';
    case '80100':
      return 'Bitte überprüfen Sie Ihre Eingabe. Die PLZ muss aus fünf Ziffern bestehen.';
    case '80200':
      return 'Leider ist das System aktuell nicht erreichbar, bitte versuchen Sie es später noch einmal.';
    case '80300':
      return 'Bitte überprüfen Sie die Eingabe. Zu der eingegebenen PLZ können wir keinen Ort zuordnen.';
    case '500':
      return 'Die Maklersuche ist zur Zeit leider nicht erreichbar.';
    default:
      return `Ein Unbekannter Fehler ist aufgetreten. Code ${errorCode}`;
  }
};

function BrokerSearch({
  inputPLZ,
  searchValue,
  reset,
  state,
  markers,
  error,
  geoLocate,
  selectMarker,
  selectedMarker,
  updateSearchResults
}: {
  inputPLZ?: string;
  searchValue: string;
  reset: () => void;
  state: 'default' | 'loading' | 'loaded';
  markers: Marker[];
  error?: {
    code?: string;
  };
  geoLocate: () => void;
  selectMarker: (marker: Marker) => void;
  selectedMarker: Marker | undefined;
  updateSearchResults: (inputValue: string, callback?: () => void) => void;
}) {
  const isMobile = useBreakpoint('m');
  const [inputValue, setInputValue] = useState('');
  const [isTouched, setIsTouched] = useState(false);
  useEffect(() => {
    if (searchValue !== inputValue) {
      setInputValue(searchValue);
    }
  }, [searchValue]);
  const [resultsShown, setResultsShown] = useState(true);

  const pushZipToDatalayer = (): void => {
    pushToDataLayer([
      { brokerSearchZip: inputValue },
      {
        event: 'brokerSearchRequest'
      }
    ]);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    setIsTouched(true);
    updateSearchResults(inputValue, pushZipToDatalayer);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsTouched(true);
    setInputValue(event.target.value);
    reset();
  };
  const [resultCollapsed, setResultCollapsed] = useState(true);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    switch (state) {
      case 'default':
        updateSearchResults(inputValue, pushZipToDatalayer);
        break;
      case 'loading':
        break;
      case 'loaded':
        setResultsShown(!resultsShown);
        break;
    }
  };

  const [plzParams, , , isPending] = useUrlParams('plz');
  useEffect(() => {
    if (inputPLZ) {
      setInputValue(inputPLZ);
      handleClick;
    } else if (plzParams) {
      setInputValue(plzParams);
      handleClick;
    }
  }, [isPending]);

  useEffect(() => {
    if (state === 'loaded') {
      setResultsShown(true);
    }
  }, [state]);

  useEffect(() => {
    if (isMobile && resultsShown) {
      setResultsShown(false);
    }
  }, [selectedMarker]);

  const activeGroups = useCookieByKey('onetrust_active_groups', true);

  const isInert = !activeGroups?.includes('C0001,C0002,C0004');

  return (
    <>
      <div className="l-broker-search BrokerSearch">
        <h2>Versicherungs&shy;vermittler in Ihrer Nähe</h2>
        <div className="l-broker-search__search m-box m-box--paper spacer-small">
          <h2>Versicherungs&shy;vermittler in Ihrer Nähe</h2>
          <p>
            Nutzen Sie unsere Vermittler-Suche, um einen Makler oder Mehrfachvertreter in Ihrer Nähe
            zu finden.
          </p>
          <form
            className="m-form"
            name="brokerSearchForm"
            onSubmit={handleSubmit}
            //inert hack, see https://stackoverflow.com/questions/72720469/error-when-using-inert-attribute-with-typescript
            {...{ inert: isInert ? '' : undefined }}
          >
            <div className="m-search-field">
              <i
                className={classNames('m-search-field__left-icon', {
                  'ico-crosshair': state === 'default' || state === 'loaded',
                  'ico-spinner': state === 'loading'
                })}
                onClick={geoLocate}
              ></i>
              <input
                type="number"
                placeholder="Ihre Postleitzahl&hellip;"
                required
                value={inputValue}
                onChange={handleChange}
                inputMode="numeric"
                data-touched={isTouched}
              />

              <button
                className="tag-broker-search-request"
                type={state === 'default' || state === 'loading' ? 'submit' : 'button'}
                onClick={handleClick}
                aria-label={
                  state === 'default' || state === 'loading'
                    ? 'Suchen'
                    : resultsShown
                      ? 'Ergebnisse Zeigen'
                      : 'Ergebnisse ausblenden'
                }
                aria-expanded={resultsShown}
              >
                <i
                  className={classNames({
                    'ico-arrow-right': state === 'default' || state === 'loading',
                    'ico-list': state === 'loaded'
                  })}
                ></i>
              </button>
            </div>
            {error && (
              <div className="m-form__validation-message">
                <div>
                  <p role="alert"> {getErrorMessage(error.code)} </p>
                </div>
              </div>
            )}
          </form>
        </div>
        {state === 'loaded' && resultsShown && !error && (
          <BrokerSearchResults
            markers={markers}
            selectMarker={selectMarker}
            selectedMarker={selectedMarker}
          />
        )}
        <div>
          <CookieBox />
        </div>
      </div>

      {isMobile && selectedMarker && (
        <div
          className={classNames('l-broker-search__result m-box m-box--paper', {
            collapsed: resultCollapsed
          })}
          onClick={() => setResultCollapsed(!resultCollapsed)}
        >
          <header>
            <h3>{selectedMarker.organization}</h3>
            <span>
              <a
                href={`https://www.google.de/maps/dir//${selectedMarker.street},+${selectedMarker.zip}+${selectedMarker.city}`}
                target="_blank"
                rel="noreferrer"
              >
                <Icon type="route" className="ico-route"></Icon>
              </a>
            </span>
          </header>
          <div>
            <div>
              <address>
                {selectedMarker.street} <br />
                {selectedMarker.zip} {selectedMarker.city}
                {selectedMarker.phone && (
                  <span>
                    <br />
                    <br />
                    Tel.: {selectedMarker.phone}
                  </span>
                )}
                {selectedMarker.fax && (
                  <span>
                    <br />
                    Fax: {selectedMarker.fax}
                  </span>
                )}
              </address>
              <aside>
                <a
                  className="m-button m-button--sec m-button--condensed phone-button tag-broker-search-call-button"
                  href={`tel://${selectedMarker.phone}`}
                >
                  Anrufen
                </a>

                <button
                  fullscreen-overlay-slide-trigger="brokerSearchCallback"
                  className="m-button m-button--sec m-button--condensed callback-button tag-broker-search-callback-button"
                >
                  Rückruf-Wunsch
                </button>
                <button
                  fullscreen-overlay-slide-trigger="brokerSearchContact"
                  className="m-button m-button--sec m-button--condensed  tag-broker-search-mail-button"
                >
                  E-Mail schreiben
                </button>
              </aside>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BrokerSearch;
