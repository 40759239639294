import React from 'react';

import Image from '../ui/Image/Image';

const SignetCardComponent = ({ card, index }) => {
  const style = { '--index': index } as React.CSSProperties;

  return (
    <div className="SignetCard" style={style}>
      <div className="SignetCard__inner">
        <div className="SignetCard__imageWrapper">
          <Image objectFitContain src={card.img} sizes={{ w: 215 }} />
        </div>
        <div className="SignetCard__text">
          <h3 className="SignetCard__title">{card.title}</h3>
          <div className="SignetCard__description">{card.description}</div>
        </div>
      </div>
    </div>
  );
};

export default SignetCardComponent;
