import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';

import { TLinkType } from '../components/ui/Link/Link';
import SiteDataContext from './SiteDataContext';

interface IProductGroupsContextValue {
  productGroups: any;
  activeProduct: ProductGroup;
  setActiveProduct: Dispatch<SetStateAction<ProductGroup>>;
  getProductGroup: (key: string, value: string) => any;
  hideProducts: boolean;
  setHideProducts: Dispatch<SetStateAction<boolean>>;
}

interface ICustomContact {
  heading: string;
  phoneNumber: string;
  availability: string;
}

type IContactMethod = 'standard' | 'expert' | 'custom';

export interface ProductGroup {
  contact?: any;
  calcIcon?: string;
  calcLabel?: string;
  description?: string;
  icon?: string;
  name?: string;
  navigationTitle?: string;
  path?: string;
  template?: string;
  title?: string;
  title_original?: string;
  linkType?: TLinkType;
  linkTo?: any;
  hideCta?: string;
  showExpert?: boolean;
  contactMethod?: IContactMethod;
  customContact?: ICustomContact;
  children?: any;
}

const initialValue = {
  productGroups: {},
  activeProduct: {},
  setActiveProduct: null,
  getProductGroup: null,
  hideProducts: false,
  setHideProducts: null
};

const ProductGroupsContext = createContext<IProductGroupsContextValue>(initialValue);

const flatProductGroups = [];

const flattenProductGroups = node => {
  node &&
    Object.keys(node).forEach(key => {
      if (typeof node[key] === 'object' && !Array.isArray(node[key])) {
        flattenProductGroups(node[key]);
      } else if (node.id && !flatProductGroups.find(productGroup => productGroup.id === node.id)) {
        flatProductGroups.push(node);
      }
    });
};

const getProductGroup = (key: string, value: string) =>
  flatProductGroups.find(productGroup => productGroup[key] === value);

export const ProductGroupsContextProvider = ({ productGroups, children }) => {
  flattenProductGroups(productGroups);
  const [hideProducts, setHideProducts] = useState(false);
  const [activeProduct, setActiveProduct] = useState(null);
  const { location } = useContext(SiteDataContext) || {};

  useEffect(() => {
    let { pathname = '' } = location;
    pathname = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;

    if (getProductGroup('path', pathname)) {
      setActiveProduct(getProductGroup('path', pathname));
    } else setActiveProduct(null);
  }, [location, flatProductGroups]);

  return (
    <ProductGroupsContext.Provider
      value={{
        productGroups,
        activeProduct,
        setActiveProduct,
        getProductGroup,
        hideProducts,
        setHideProducts
      }}
    >
      {children}
    </ProductGroupsContext.Provider>
  );
};

export default ProductGroupsContext;
