import ArticlesArea from '../areas/ArticlesArea/ArticlesArea';
import CorporateContactFooter from '../components/ui/CorporateContact/CorporateContactFooter';
import FlotteStage from '../components/ui/FlotteStage/FlotteStage';
import ForderungsausfallStage from '../components/ui/ForderungsausfallStage/ForderungsausfallStage';
import React from 'react';
import extractChildren from '../helpers/extractChildren';

const CorporateHomePage = ({ children }) => {
  const { headerArea, contentArea } = extractChildren(children);
  return (
    <>
      {headerArea}
      <main className="l-content l-content--corporate">
        <ForderungsausfallStage />
        <ArticlesArea variant="teaser" />
        {contentArea}
        {/* <section className="l-content__section">{teaserArea}</section> */}
        <CorporateContactFooter />
      </main>
    </>
  );
};

export default CorporateHomePage;
