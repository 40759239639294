import './ForderungsausfallStage.scss';

import React from 'react';

import useBreakpoint from '../../../hooks/useBreakpoint';
import ButtonComponent from '../../ButtonComponent/ButtonComponent';
import ProductFinder from '../ProductFinder/ProductFinder';

const ForderungsausfallStage = () => {
  const BreakpointM = useBreakpoint('m');
  return (
    <>
      <div className="ForderungsausfallStage">
        <div className="ForderungsausfallStage__wrapper">
          {BreakpointM && <div className="ForderungsausfallStage__fade" />}
          <div className="ForderungsausfallStage__text">
            <p className="ForderungsausfallStage__subline">Die VHV Forderungsausfallversicherung</p>
            <h2 className="ForderungsausfallStage__headline">
              WENN IHR KUNDE NICHT ZAHLT, ZAHLEN WIR!
            </h2>
          </div>
          <div className="ForderungsausfallStage__button">
            <ButtonComponent
              label="Jetzt informieren"
              linkTo="/firmen/produkte/forderungsausfall"
              linkType="internal"
              alignment="center"
              variant="opaque"
            />
          </div>
        </div>
        {!BreakpointM && <ProductFinder />}
      </div>
      {BreakpointM && <ProductFinder />}
    </>
  );
};

export default ForderungsausfallStage;
