import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';

import MaintenanceBannerComponent from '../../components/MaintenanceBannerComponent/MaintenanceBannerComponent';
import Icon from '../../components/ui/Icon/Icon';
import Link from '../../components/ui/Link/Link';
import ContactInformationContext from '../../contexts/ContactInformationContext';
import CorporateContactContext from '../../contexts/CorporateContactContext';
import ProductGroupsContext from '../../contexts/ProductGroupsContext';
import SiteDataContext from '../../contexts/SiteDataContext';
import getCalculatorPath from '../../helpers/getCalculatorPath';
import useBreakpoint from '../../hooks/useBreakpoint';
import useOutsideClick from '../../hooks/useOutsideClick';
import useScrollPosition from '../../hooks/useScrollPosition';
import LandingPageHeaderArea from './LandingPageHeaderArea';
import MainNavItem from './MainNavItem';
import OffcanvasMenu from './OffcanvasMenu';

const HeaderArea = ({ parentTemplate = '' }) => {
  const { navs = {}, pageScope, location = {} } = useContext(SiteDataContext) || {};

  const { pathname } = location;

  const nav = navs && pageScope && navs[pageScope] ? navs[pageScope] : [];

  const msNav = nav.find(item => pathname.startsWith(item.path))?.children;

  const [isOffcanvasNavOpen, setIsOffcanvasNavOpen] = useState<boolean>(false);
  const [selectedMainNavItem, setSelectedMainNavItem] = useState<string>('');

  const { setIsCorporateContactModalVisible } = useContext(CorporateContactContext) || {};

  const { getExpertSearchCta } = useContext(CorporateContactContext) || {};

  const { activeProduct: product } = useContext(ProductGroupsContext) || {};

  const {
    linkTo: calculatorPage,
    hideCta,
    calcIcon,
    calcLabel,
    contact,
    showExpert,
    contactMethod
  } = product || {};

  const showExpertSearch = contactMethod == 'expert' || (!contactMethod && showExpert);

  const { expertCallback, expertLabel, expertIconClass } =
    getExpertSearchCta(showExpertSearch || pageScope === 'expert', true) || {};

  const { setIsContactInformationModalVisible } = useContext(ContactInformationContext) || {};

  const visibleSubMenusReducer = (visibleSubMenus, action) => {
    const { value, type } = action;

    switch (type) {
      case 'set':
        return value;
      case 'push':
        return !visibleSubMenus.includes(value) ? [...visibleSubMenus, value] : visibleSubMenus;
      case 'pop':
        return visibleSubMenus.length !== 0
          ? visibleSubMenus.slice(0, visibleSubMenus.length - 1)
          : visibleSubMenus;
    }
  };
  const isMaxVpS = useBreakpoint('s');
  const [visibleSubMenus, dispatchVisibleSubMenus] = useReducer(visibleSubMenusReducer, []);

  const clearSelectedMainNavItem = () => {
    setSelectedMainNavItem('');
  };

  const toggleSelectedMainNavItem = item => {
    if (selectedMainNavItem === item) {
      clearSelectedMainNavItem();
    } else {
      setSelectedMainNavItem(item);
    }
  };

  const offCanvasRef = useRef(null);

  useOutsideClick(offCanvasRef, () => setIsOffcanvasNavOpen(false), isOffcanvasNavOpen);

  const scrollPos = useScrollPosition();

  const allProductsDoorPage = nav.find(mainNavItem => {
    const { template } = mainNavItem || {};
    return template === 'AllProductsDoorPage';
  });

  const { children: privateMainNavItems } = allProductsDoorPage || {};

  const isPrivateScope = pageScope === 'private';

  const isMsScope = pageScope === 'ms';

  const isCorporateScope = pageScope === 'corporate';

  const isExpertScope = pageScope === 'expert';

  const [isHeaderSticky, setIsHeaderSticky] = useState(false);

  const headerRef = useRef(null);

  useEffect(() => {
    const headerHeight = headerRef?.current?.clientHeight;
    const breakpoint = 400;
    if (scrollPos >= breakpoint && !isMsScope) {
      setIsHeaderSticky(true);
    }
    if (isHeaderSticky && scrollPos < breakpoint - headerHeight) {
      setIsHeaderSticky(false);
    }
  }, [scrollPos]);

  const mainNavItems = isPrivateScope
    ? privateMainNavItems
    : nav.filter(navItem => navItem.name !== 'firmen');

  //Todo @Ilja: add gatsby routes context to check homepage path === /
  const isHomePage = false;

  //Todo @Ilja: stage?
  const stage = undefined;
  const isUnfallStage = stage && stage.campaignTag && stage.campaignTag === 'unfall';

  const className = `nocontent l-header
  ${isHomePage ? ' l-header--home' : ''}
  ${isUnfallStage ? ' l-header--filled' : ''}
  ${isHeaderSticky ? ' l-header--sticky' : ''}
  ${isMsScope ? ' l-header--branding' : ''}`;

  const homeLink = isPrivateScope || isExpertScope ? '/' : isMsScope ? '' : '/firmen';

  const staticOffcanvasLinks = [
    {
      name: 'presse',
      title: 'Presse',
      path: 'https://www.vhv-gruppe.de/de/newsroom/newsroom',
      navIcon: { icon: 'press' },
      pathType: 'external'
    },
    {
      name: 'karriere',
      title: 'Karriere',
      path: 'https://www.vhv-gruppe.de/de/karriere/uebersicht',
      navIcon: { icon: 'profil' },
      pathType: 'external'
    }
  ];

  if (isMaxVpS && isPrivateScope) {
    staticOffcanvasLinks.push({
      name: 'kundenportal',
      title: 'Kundenportal',
      path: 'https://www.vhv.de/kundenportal',
      navIcon: { icon: 'noun-login' },
      pathType: 'external'
    });
  }

  const secNav = isPrivateScope ? (
    <>
      <li className="tag-service-button">
        <Link linkTo="https://www.vhv.de/kundenportal" linkType="external" label="Service">
          <Icon type="noun-login-thin" className="l-header__larger-icon" />
          <span>Kundenportal</span>
        </Link>
      </li>
      <li className="tag-service-button">
        <Link
          linkTo="/"
          onClick={() => {
            setIsContactInformationModalVisible(true, {
              isDamageVariant: true
            });
          }}
        >
          <Icon type="warning-thin" className="l-header__larger-icon" />
          <span>Schaden melden</span>
        </Link>
      </li>
      <li className="tag-service-button">
        <Link linkTo="/kontakt" label="Service">
          <Icon type="hotline-thin" className="l-header__larger-icon" />
          <span>Service & Kontakt</span>
        </Link>
      </li>
      <li className="tag-branch-selection-button">
        <Link linkTo="/firmen">
          <span>Firmenkunden</span>
        </Link>
      </li>
    </>
  ) : (
    (pageScope === 'corporate' || pageScope === 'expert') && (
      <>
        <li
          className={
            pageScope === 'expert'
              ? 'l-header__sec-nav__expert'
              : 'l-header__tag-service-button-sticky'
          }
          onClick={() => expertCallback()}
        >
          <Icon type="hotline" className={expertIconClass} />
          {expertLabel}
        </li>
        {pageScope === 'corporate' && (
          <li className="tag-branch-selection-button">
            <Link linkTo="/" label="Privatkunden" />
          </li>
        )}
      </>
    )
  );

  const content = (
    <>
      <Link linkTo={homeLink} extraClass="l-header__logo">
        <Icon
          type="vhv-logo-full-alt"
          size={{ width: '100%', height: isMaxVpS ? 'auto' : '100%' }}
        />
      </Link>
      <nav>
        <ul className="l-header__button-group">
          {!hideCta && isPrivateScope && (
            <>
              {contact && (
                <li>
                  <button
                    className="m-button m-button--light tag-sticky-contact ga-sticky-contact"
                    onClick={() => {
                      setIsContactInformationModalVisible(true, {
                        isActiveProductVariant: true
                      });
                    }}
                  >
                    <Icon type="hotline" className="ico-hotline ga-sticky-contact"></Icon> Beraten
                    lassen
                  </button>
                </li>
              )}

              {calculatorPage && (
                <li>
                  <Link
                    {...getCalculatorPath(calculatorPage)}
                    extraClass="m-button tag-sticky-calc ga-sticky-calc calculator-button"
                  >
                    <Icon
                      type={calcIcon}
                      className={`ico-${calcIcon} ga-content-header-calc`}
                    ></Icon>
                    {calcLabel || 'Beitrag berechnen'}
                  </Link>
                </li>
              )}
            </>
          )}
        </ul>

        <ul className={`l-header__nav${isPrivateScope ? '' : ' l-header__nav--corporate'}`}>
          {!isMsScope &&
            mainNavItems
              ?.filter(item => !item.onlyInBurgerMenu)
              .map((mainNavItem, index) => (
                <MainNavItem
                  key={index}
                  mainNavItem={mainNavItem}
                  toggleSelectedMainNavItem={toggleSelectedMainNavItem}
                  clearSelectedMainNavItem={clearSelectedMainNavItem}
                  selectedMainNavItem={selectedMainNavItem}
                />
              ))}
          {pageScope === 'corporate' && (
            <li onClick={() => setIsCorporateContactModalVisible(true)}>
              <a>Kontakt</a>
            </li>
          )}
          {(isMsScope ? !!msNav : nav.length > 0) && (
            <li className="burger-button" onClick={() => setIsOffcanvasNavOpen(true)}>
              <a>
                <div className="l-header__burger-icon">
                  <span></span>
                </div>
              </a>
            </li>
          )}
        </ul>

        <ul
          className={`l-header__sec-nav${isCorporateScope || isExpertScope ? ' l-header__sec-nav--corporate' : ''}`}
        >
          {secNav}
        </ul>
      </nav>
    </>
  );

  return parentTemplate === 'LandingPage' ? (
    <LandingPageHeaderArea />
  ) : (
    <>
      <div className="l-header-wrapper">
        <MaintenanceBannerComponent />
        <header ref={headerRef} className={className}>
          <div>{isHomePage ? <h1>{content}</h1> : content}</div>
        </header>
      </div>
      <nav className={`l-offcanvas-navigation nocontent${isOffcanvasNavOpen ? ' open' : ''}`}>
        <div className="l-offcanvas-navigation__level" ref={offCanvasRef}>
          <header
            className="l-offcanvas-navigation__header"
            onClick={() => setIsOffcanvasNavOpen(false)}
          >
            <h4>Menü</h4>
            <button className="l-offcanvas-navigation__header__close-button">
              <Icon type="close" className="ico-close" />
            </button>
          </header>
          <OffcanvasMenu
            nav={isMsScope ? msNav : [...nav, ...staticOffcanvasLinks]}
            visibleSubMenus={visibleSubMenus}
            dispatchVisibleSubMenus={dispatchVisibleSubMenus}
            closeOffcanvas={() => setIsOffcanvasNavOpen(false)}
          />
        </div>
      </nav>
    </>
  );
};

export default HeaderArea;
