import React, { useContext, useState } from 'react';

import ExpertSearchContext from '../../../contexts/ExpertSearchContext';
import SiteDataContext from '../../../contexts/SiteDataContext';
import getFormEngineWebBaseUrl from '../../../helpers/getFormEngineWebBaseUrl';
import Image from '../../ui/Image/Image';
import FullscreenOverlay from '../FullscreenOverlay/FullscreenOverlay';
import FullscreenOverlaySlider, {
  FullScreenOverlaySliderContext
} from '../FullscreenOverlaySlider/FullscreenOverlaySlider';
import Icon from '../Icon/Icon';
import Link from '../Link/Link';

const ExpertSearchInitSlide = () => {
  const { isLoading, findExpert } = useContext(ExpertSearchContext) || {};
  const slideTransition = useContext(FullScreenOverlaySliderContext) || {};
  const [inputValue, setInputValue] = useState('');
  const [isTouched, dispatchIsTouched] = useState(false);

  const setIsTouched = () => {
    if (!isTouched) {
      dispatchIsTouched(true);
    }
  };
  const postalCodeRegex = /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/;

  return (
    <div>
      <header>
        <Icon type="hotline" className="ico-hotline"></Icon>
        <h2>Ihr Experte vor Ort</h2>
      </header>

      <form
        noValidate
        name="expertSearchForm"
        className="m-form"
        onSubmit={e => {
          e.preventDefault();
          if (!isTouched && !inputValue.match(postalCodeRegex)) {
            setIsTouched();
          } else if (inputValue.match(postalCodeRegex)) {
            findExpert(inputValue, () => slideTransition('next'));
          }
        }}
      >
        <div className="m-form__group m-form__group--giant">
          <div className="l-grid__col--10-12 l-grid__col--keep-width">
            <input
              type="text"
              value={inputValue}
              disabled={isLoading}
              placeholder="Ihre Postleitzahl"
              required
              onChange={e => {
                setIsTouched();
                setInputValue(e.target.value);
              }}
            />
          </div>
          <div className="l-grid__col--2-12 l-grid__col--keep-width text-right spacer-small">
            <button type="submit" className="m-button m-button--condensed tag-dba-request">
              {isLoading ? (
                <Icon type="spinner" className="ico-spinner" />
              ) : (
                <Icon type="search" className="ico-search" />
              )}
            </button>
          </div>
          {isTouched && !inputValue.match(postalCodeRegex) && (
            <div className="l-grid__col">
              <div className="m-form__validation-message">
                <div>
                  <p>Bitte geben Sie eine gültige Postleitzahl ein.</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

const ExpertSearchModal = () => {
  const { isExpertSearchModalVisible, setIsExpertSearchModalVisible, isLoading, expert } =
    useContext(ExpertSearchContext) || {};

  const {
    photoUrl,
    firstName,
    lastName,
    street,
    zip,
    city,
    telephoneNumber,
    faxNumber,
    email: encryptedEmail,
    brokerNumber,
    gender
  } = expert || {};

  const [brokerBcVariant, setBrokerBcVariant] = useState<string>('');

  const { businessCardReferences = [] } = useContext(SiteDataContext) || {};

  const brokerBcSrc = `${getFormEngineWebBaseUrl()}/formEngineWeb/vhv/formular-dba-${brokerBcVariant}.htm?datato=${encryptedEmail}`;

  return (
    <FullscreenOverlay
      isVisible={isExpertSearchModalVisible}
      hideOverlay={() => setIsExpertSearchModalVisible(false)}
    >
      <FullscreenOverlaySlider initialSlide={expert ? 1 : 0}>
        <ExpertSearchInitSlide />
        {!isLoading && expert && (
          <div>
            <header>
              <Icon type="hotline" className="ico-hotline"></Icon>
              <h2>{gender === 'f' ? 'Ihre Expeterin' : 'Ihr Experte'} vor Ort</h2>
            </header>
            <div className="m-asp m-asp--overlay">
              <div>
                <div className="m-asp__information">
                  {photoUrl && (
                    <figure>
                      <Image src={photoUrl} />
                    </figure>
                  )}
                  <div>
                    <strong>
                      {firstName} {lastName}
                    </strong>
                    <address>
                      <div className="spacer-small">
                        {street}
                        <br />
                        {zip} {city}
                      </div>

                      {telephoneNumber && <div>Tel.: {telephoneNumber}</div>}
                      {faxNumber && <div>Fax: {faxNumber}</div>}
                    </address>
                  </div>
                </div>
                <FullScreenOverlaySliderContext.Consumer>
                  {slideTransition => (
                    <div className="m-asp__cta">
                      <div>
                        <button
                          className="m-button m-button--sec m-button--condensed callback-button tag-dba-callback-button"
                          onClick={() => {
                            slideTransition('next');
                            setBrokerBcVariant('rueckruf');
                          }}
                        >
                          Rückruf vereinbaren
                        </button>
                        <a className="m-button m-button--sec m-button--condensed call-button tag-dba-call-button">
                          Anrufen
                        </a>
                        <button
                          className="m-button m-button--medium m-button--condensed tag-dba-mail-button"
                          onClick={() => {
                            slideTransition('next');
                            setBrokerBcVariant('email');
                          }}
                        >
                          E-Mail schreiben
                        </button>
                        {businessCardReferences[brokerNumber] && (
                          <Link
                            label="zum Online-Profil"
                            extraClass="m-button m-button--medium m-button--condensed"
                            linkTo={businessCardReferences[brokerNumber]}
                            onClick={() => setIsExpertSearchModalVisible(false)}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </FullScreenOverlaySliderContext.Consumer>
              </div>
            </div>
          </div>
        )}
        {expert && !isLoading && <iframe className="itform" src={brokerBcSrc}></iframe>}
      </FullscreenOverlaySlider>
    </FullscreenOverlay>
  );
};

export default ExpertSearchModal;
