import './KfzInsuranceTypesComponent.scss';

import React, { useState } from 'react';

import Container from '../ui/Container/Container';
import Icon from '../ui/Icon/Icon';

const KfzInsuranceTypesComponent = () => {
  const [selected, setSelected] = useState(1);

  const insuranceTypes = [
    {
      name: 'Haftpflicht',
      icon: 'shield-single',
      heading: 'Haftpflicht',
      subHeading: (
        <span>
          Die <strong>gesetzliche Pflicht-Versicherung</strong> übernimmt die Schäden, die Sie an{' '}
          <strong>fremden Fahrzeugen</strong> und Personen verursachen.
        </span>
      ),
      basics: [
        <span key="1">
          Unfallschaden an <strong>anderen Fahrzeugen</strong>
        </span>,
        <span key="2">
          Fremde <strong>Personen</strong>, <strong>Sach-</strong> und <strong>Vermögens</strong>
          schaden
        </span>
      ],
      suitable: [
        <span key="1">
          Für <strong>ältere Gebrauchtwagen</strong> mit{' '}
          <strong>niedrigen Wiederverkaufswert</strong>
        </span>,
        <span key="2">
          Wenn Sie Schäden am <strong>eigenen Auto</strong> aus der <strong>eigenen Tasche</strong>{' '}
          bezahlen können
        </span>
      ],
      addons: ''
    },
    {
      name: 'Teilkasko',
      icon: 'shield-double',
      heading: 'Teilkasko-Schutz',
      subHeading: (
        <span>
          Die Teilkasko-Versicherung schützt Sie bei Schäden an Ihrem <strong>eigenen Auto</strong>.
          Allerdings nur bei Schäden, die Sie <strong>nicht selbst verursacht</strong> haben.
        </span>
      ),
      basics: [
        <span key="1">
          Alle Leistungen der <strong>Haftpflicht inklusive</strong>
        </span>
      ],
      suitable: [
        <span key="1">
          Für <strong>Gebrauchtwagen</strong> mit <strong>hohem Wiederverkaufswert</strong>
        </span>,
        <span key="2">
          Wenn Sie Schäden am <strong>eigenen Auto</strong> nicht aus der{' '}
          <strong>eigenen Tasche</strong> bezahlen können
        </span>
      ],
      addons: (
        <span>
          Zusätzlich Absicherung von <strong>Schäden</strong> am <strong>eigenen Fahrzeug</strong>,
          bspw. <strong>Diebstahl</strong>, <strong>Tierbiss</strong>, <strong>Unwetter</strong>{' '}
          (u.a. durch Klimawandel), <strong>Brand</strong>, <strong>Glasbruch</strong> oder{' '}
          <strong>Zusammenstoß</strong> mit einem Tier.
        </span>
      )
    },
    {
      name: 'Vollkasko',
      icon: 'shield-triple',
      heading: 'Vollkasko-Schutz',
      subHeading: (
        <span>
          Die <strong>Vollkasko-Versicherung</strong> schützt Sie <strong>rundum</strong> bei
          Schäden an Ihrem <strong>eigenen Auto</strong>. Auch bei Schäden, die Sie{' '}
          <strong>selbst verursacht</strong> haben.
        </span>
      ),
      basics: [
        <span key="1">
          Alle <strong>Haftpflicht</strong>- u.<strong>Teilkasko</strong>-Leistungen inkl.
        </span>
      ],
      suitable: [
        <span key="1">Für Neuwagen mit Autos mit hohem Restwert</span>,
        <span key="2">
          Wenn Sie Schäden am <strong>eigenen Auto</strong> nicht aus der{' '}
          <strong>eigenen Tasche</strong> bezahlen können
        </span>,
        <span key="3">
          Wenn Sie Ihr Auto <strong>geleast</strong> haben
        </span>
      ],
      addons: (
        <span>
          Zusätzlich Absicherung von <strong>Unfallschäden</strong> am <strong>eigenen</strong>{' '}
          Fahrzeug und Schäden durch <strong>Vandalismus</strong>.
        </span>
      )
    }
  ];

  return (
    <Container>
      <div className="KfzInsuranceTypesComponent">
        <h2>
          Was ist der <strong>richtige Schutz</strong> für mich?
        </h2>
        <div className="KfzInsuranceTypesComponent__icons">
          {insuranceTypes.map((insurance, i) => {
            return (
              <div
                key={i}
                onClick={() => setSelected(i)}
                className={`KfzInsuranceTypesComponent__iconContainer${i == selected ? ' KfzInsuranceTypesComponent__iconContainer--selected' : ''}`}
              >
                <Icon type={insurance.icon} />
                <div>{insurance.name}</div>
              </div>
            );
          })}
        </div>
        <div className="KfzInsuranceTypesComponent__sectionWrapper">
          <h3>{insuranceTypes[selected].heading}</h3>
          <p> {insuranceTypes[selected].subHeading}</p>
          <div className="KfzInsuranceTypesComponent__boxWrapper">
            <div className="KfzInsuranceTypesComponent__boxWrapperInner">
              <div className="KfzInsuranceTypesComponent__leftBox">
                <h4>
                  <strong>Was</strong> ist versichert?
                </h4>
                <ul>
                  {insuranceTypes[selected].basics.map((basic, i) => (
                    <li key={i}>{basic}</li>
                  ))}
                </ul>
              </div>
              {insuranceTypes[selected].addons && (
                <div className="KfzInsuranceTypesComponent__advantageWrapper">
                  {insuranceTypes[selected].addons}
                </div>
              )}
            </div>
            <div className="KfzInsuranceTypesComponent__boxWrapperInner">
              <div className="KfzInsuranceTypesComponent__rightBox">
                <h4>
                  <strong>Für wen</strong> ist sie geeignet?
                </h4>
                <ul>
                  {insuranceTypes[selected].suitable.map((suits, i) => (
                    <li key={i}>{suits}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default KfzInsuranceTypesComponent;
